import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
// import Header from "./layout/Header";
// import Footer from "./layout/Footer";
import HomePage from "./pages/HomePage";

class App extends Component {
  render() {
    return (
      <Router>
        {/* <Header /> */}
        <Switch>
          <Route exact path="/" component={HomePage} />
        </Switch>
        {/* <Footer /> */}
      </Router>
    );
  }
}

export default App;
