import React from "react";
import styled from "styled-components";
import ConstructionImage from "../resources/build.jpg";
import { colors } from "../resources/Themecolors";
import Fade from "react-reveal/Fade";
import Blob from "../resources/blob.svg";

export default function HomePage() {
  return (
    <MainSection>
      <Object1 data={Blob}></Object1>
      <MainDiv>
        <Fade left>
          <TextDiv>
            <Title>
              <span>Lenny</span> <span>A</span>rts
            </Title>
            <Description>
              <span>Remarkable Gra</span>phics Design &amp; Social Media
              management services
            </Description>
            <Button>Under Construction!</Button>
          </TextDiv>
        </Fade>

        <ImageDiv>
          <img
            src={ConstructionImage}
            alt="lenny arts website construction"
          ></img>
        </ImageDiv>
      </MainDiv>
    </MainSection>
  );
}

const MainSection = styled.div`
  display: grid;
  height: auto;
`;

const MainDiv = styled.div`
  height: auto;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  grid-area: 1/1;

  @media (min-width: 700px) {
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-start;
    grid-area: 1/1;
  }
`;

const TextDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  padding: 2em;
  z-index: 1;
  width: 80vw;
  height: auto;

  @media (min-width: 700px) {
    padding: 1em;
    height: 100vh;
    position: absolute;
    z-index: 1;
    width: 50%;
    margin-top: -3em;
  }
`;

const Title = styled.h2`
  font-size: 3em;
  font-weight: 800;
  color: ${colors.color2};
  font-family: "Poppins", sans-serif;

  @media (min-width: 700px) {
    color: ${colors.color2};
  }
`;

const Description = styled.p`
  font-size: 1.2em;
  font-weight: 500;
  color: ${colors.color2};
  text-align: center;
  font-family: "Poppins", sans-serif;
  margin-top: -1.5em;
  @media (min-width: 700px) {
    margin-top: -1em;
    width: 60%;
  }
`;

const Button = styled.button`
  background-color: ${colors.color2};
  border: none;
  color: white;
  font-size: 1.5em;
  font-weight: 1000;
  margin-top: 1em;
  font-family: "Poppins", sans-serif;
`;

const ImageDiv = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  > img {
    height: 12em;
    width: 18em;
    bottom: 15px;
    position: absolute;
    margin-top: 2em;

    @media (min-width: 700px) {
      height: 26em;
      width: 36em;
      position: absolute;
      z-index: 1;
      margin-bottom: 5em;
    }
  }

  @media (min-width: 700px) {
    margin-right: 20em;
  }
`;

const Object1 = styled.object`
  z-index: -1;
  top: -580px;
  left: -100px;
  position: relative;
  height: 300px;
  width: 250px;

  @media (min-width: 700px) {
    z-index: -1;
    top: -45px;
    left: -45px;
    position: relative;
    height: 200px;
    width: 250px;
  }
`;
